<template>
  <div style="padding: 0 0 5% 4%">
    <div style="width: 100%; text-align: center; font-size: 20px">查看方案</div>
    <div style="font-weight: 700">基础信息</div>
    <div class="flex_warp" style="margin-bottom: 10px">
      <div class="blocks2 flex_items flex_start">
        <span class="mr10 liTitle2" style="margin: 0 -25px 0 5px"
          >方案属性</span
        >
        <el-select
          v-model="nature"
          placeholder="方案属性"
          style="width: 140px"
          filterable
          disabled
        >
          <el-option
            v-for="(item, index) in attribute"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="flex_warp">
      <div class="blocks2 flex_items flex_start" style="margin-bottom: 10px">
        <span class="mr10 liTitle">方案类别</span>
        <el-select
          v-model="planKind"
          placeholder="方案类别"
          :clearable="true"
          style="width: 140px"
          filterable
          @change="kindChange"
          disabled
        >
          <el-option
            v-for="(item, index) in planKinds"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="blocks2 flex_items flex_start" style="margin-bottom: 10px">
        <span class="mr10 liTitle">方案名称</span>
        <el-input
          v-model="planName"
          placeholder="请输入"
          style="width: 140px"
          disabled
        ></el-input>
      </div>
      <div class="blocks2 flex_items flex_start" style="margin-bottom: 10px">
        <span class="mr10 liTitle">小区名称</span>
        <el-input
          v-model="estateName"
          placeholder="请输入"
          class="littleBox"
          style="width: 140px"
          disabled
        ></el-input>
      </div>
      <div class="blocks2 flex_items flex_start" style="margin-bottom: 10px">
        <span class="mr10 liTitle" style="margin-right: -8px">户型</span>
        <el-select
          v-model="houseHold"
          placeholder="请选择"
          style="width: 140px"
          filterable
          disabled
        >
          <el-option
            v-for="(item, index) in houseHolds"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="blocks2 flex_items flex_start" style="margin-bottom: 10px">
        <span class="mr10 liTitle">预计工期</span>
        <el-input
          v-model="timeLimit"
          placeholder="请输入"
          style="width: 140px"
          type="number"
          disabled
        ></el-input>
      </div>
    </div>
    <div class="flex_warp">
      <span class="mr10 liTitle">特色标签</span>
      <div style="width: 86%">
        <el-input
          v-model="item.title"
          placeholder="请输入内容"
          v-for="(item, index) in btnList"
          :key="index"
          style="width: 140px; margin: 0 10px 10px 0"
          disabled
        ></el-input>
        <!-- <el-button
          type="primary"
          size="small"
          plain
          @click="addInput"
          style="height: 30px"
          disabled
          >添加标签</el-button
        > -->
      </div>
    </div>
    <div style="font-weight: 700; margin-bottom: 10px">方案标签</div>
    <!-- flex: 0 0 30%; -->
    <div v-if="problems.length != 0" style="width: 92%">
      <div
        v-for="(item, index) in problems"
        :key="index"
        style="
          border: 1px solid #c0c4cc;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 8px;
        "
      >
        <span class="f-bold f-16">{{ problems[0].title }}</span>
        <div style="margin: 8px 0 8px 0; font-size: 14px">位置</div>
        <el-checkbox-group v-model="labelCheck">
          <el-checkbox-button
            v-for="(item1, index1) in item.position"
            :key="index1"
            :label="item1.id"
            onclick="return false;"
            >{{ item1.title }}</el-checkbox-button
          >
        </el-checkbox-group>
        <div style="margin: 8px 0 8px 0; font-size: 14px">问题类型</div>
        <el-checkbox-group v-model="labelCheck1">
          <el-checkbox-button
            v-for="(item2, index2) in item.problem"
            :key="index2"
            :label="item2.id"
            onclick="return false;"
            >{{ item2.title }}</el-checkbox-button
          >
        </el-checkbox-group>
      </div>
    </div>
    <div
      v-else
      style="
        width: 91%;
        height: 50px;
        line-height: 50px;
        border: 1px solid #ddd;
        text-align: center;
        margin: 0 0 10px 5px;
      "
    >
      --暂无数据，请先选择方案类别--
    </div>
    <div class="flex_warp" style="margin-bottom: 10px">
      <span class="mr10 liTitle">温馨提示</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="tip"
        style="width: 86%"
        disabled
      >
      </el-input>
    </div>
    <div class="flex_warp" style="margin-bottom: 10px">
      <span class="mr10 liTitle">计价规则</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="priceRule"
        style="width: 86%"
        disabled
      >
      </el-input>
    </div>
    <div class="flex_warp" style="margin-bottom: 10px">
      <span class="mr10 liTitle">服务内容</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="serviceCon"
        style="width: 86%"
        disabled
      >
      </el-input>
    </div>
    <div class="flex_warp" style="margin-bottom: 10px">
      <span class="mr10 liTitle">使用材料</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="useMaterial"
        style="width: 86%"
        disabled
      >
      </el-input>
    </div>
    <div class="flex_warp" style="margin-bottom: 10px">
      <span class="mr10 liTitle">服务工期</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="serviceTime"
        style="width: 86%"
        disabled
      >
      </el-input>
    </div>
    <div class="flex_warp" style="margin-bottom: 10px">
      <span class="mr10 liTitle">完工验收</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="finished"
        style="width: 86%"
        disabled
      >
      </el-input>
    </div>
    <div class="flex_warp" style="margin-bottom: 10px">
      <span class="mr10 liTitle">质保信息</span>
      <el-input
        type="textarea"
        placeholder="输入内容，回车可换行"
        v-model="warrantyMsg"
        style="width: 86%"
        disabled
      >
      </el-input>
    </div>
    <div class="flex_warp" style="margin-bottom: 10px">
      <span class="mr10 liTitle">方案简介</span>
      <el-input
        type="textarea"
        placeholder="请输入200字以内的描述"
        v-model="planIntro"
        style="width: 86%"
        maxlength="200"
        disabled
      >
      </el-input>
    </div>
    <div class="flex_start">
      <div style="font-weight: 700; margin: 10px 0">方案清单</div>
      <el-select
        v-model="city"
        placeholder="选择城市"
        :clearable="true"
        style="width: 200px; margin: 5px 0 0 25px"
        filterable
        @change="cityChange"
        disabled
      >
        <el-option
          v-for="item in citys"
          :key="item.city_id"
          :label="item.city"
          :value="item.city_id"
        ></el-option>
      </el-select>
      <!-- <el-button
        plain
        size="mini"
        type="primary"
        @click="addProject"
        style="margin-left:20px;height:30px;margin-top:5px;"
        >+增加分组</el-button
      > -->
    </div>
    <div
      style="padding-right: 5%; margin: 10px 0 10px 0"
      v-for="(item, index) in project"
      :key="`${item.title}-${index}`"
    >
      <div class="flex_start">
        <!-- <el-image
          style="width: 20px; height: 20px;cursor:pointer;margin-right:5px;margin-top:5px;"
          src="https://imgaes.yiniaoweb.com/Icon/basicPlan/del.png"
          @click="sureDel(index)"
        ></el-image> -->
        <!-- @click="changeTitle(index)" -->
        <div
          style="margin-right: 20px; margin-top: 5px; font-size: 13px"
          class="titleChange"
        >
          {{ item.title }}
        </div>
      </div>
      <avue-crud :option="option" :data="item.data">
        <template slot-scope="scope" slot="menu">
          <div>
            <el-button
              size="mini"
              type="primary"
              plain
              @click="delDetail(scope.row, index)"
              disabled
              >删除</el-button
            >
          </div>
        </template>
      </avue-crud>
      <el-button
        type="primary"
        plain
        size="small"
        style="margin: 10px 0"
        @click="addDetail(index)"
        disabled
        >+增加清单项目</el-button
      >
    </div>
    <div style="font-weight: 700; margin-bottom: 10px">图片展示</div>
    <el-upload
      action="https://public.yiniao.co/api/v1/upload"
      list-type="picture-card"
      :multiple="mutrue"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="upsuccess"
      :file-list="fileList"
      :data="tuzhi"
      disabled
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-input
      style="width: 150px; margin-right: 5px"
      v-model="item.title"
      v-for="item in imgs"
      :key="item.path"
    ></el-input>
    <el-dialog
      :visible.sync="tudiag"
      append-to-body
      center
      :key="imgNum"
      width="50%"
      @close="closeImg"
    >
      <div class="prDiv">
        <img width="100%" :src="dialogImageUrl" alt class="img" />
      </div>
    </el-dialog>
    <div style="font-weight: 700; margin: 10px 0 10px 10px">关联检测结果</div>
    <div class="flex_start">
      <div>
        <el-select
          v-model="result1"
          placeholder="选择问题"
          :clearable="true"
          style="width: 150px"
          filterable
          @change="getRelation"
          @focus="cityTip"
          disabled
        >
          <el-option
            v-for="(item, index) in problemTitle"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-select
          v-model="result2"
          placeholder="结果"
          :clearable="true"
          style="width: 550px; margin: 0 0 20px 20px"
          filterable
          multiple
          disabled
        >
          <el-option
            v-for="(item, index) in result2s"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- <div style="width: 100%; text-align: center">
      <el-button type="primary" plain size="medium" @click="referPlan"
        >提交</el-button
      >
    </div> -->
    <!-- 添加清单弹框 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      append-to-body
      center
      :key="littleKey3"
      @close="closeDialog"
    >
      <div style="margin-left: 20%">
        <el-form :model="form">
          <el-form-item
            label="产品分类:"
            :label-width="formLabelWidth"
            style="color: #606266"
          >
            <el-select
              v-model="form.tao"
              placeholder="选择产品分类"
              :clearable="true"
              style="width: 240px"
              filterable
              @change="getTypes1"
            >
              <el-option
                v-for="(item, index) in taos"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="form.tao == 0 || form.tao == 1"
            label="类别名称:"
            :label-width="formLabelWidth"
            style="color: #606266"
          >
            <el-select
              v-model="form.firstType"
              placeholder="类别名称"
              :clearable="true"
              style="width: 240px"
              filterable
              @change="getTypes2"
            >
              <el-option
                v-for="(item, index) in firstTypes"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="form.tao == 0 || form.tao == 1"
            label="项目名称:"
            :label-width="formLabelWidth"
            style="color: #606266"
          >
            <el-select
              v-model="form.projectId"
              placeholder="项目名称"
              :clearable="true"
              style="width: 240px"
              filterable
              @change="getTypeDet"
            >
              <el-option
                v-for="(item, index) in projectIds"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="form.tao == 2"
            label="标题:"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="form.projectTitle"
              autocomplete="off"
              style="width: 240px"
            ></el-input>
          </el-form-item>
          <el-form-item label="单位:" :label-width="formLabelWidth">
            <el-input
              v-if="form.tao == 0 || form.tao == 1"
              v-model="form.title"
              autocomplete="off"
              style="width: 240px"
              disabled
            ></el-input>
            <el-select
              v-if="form.tao == 2"
              v-model="form.title"
              placeholder="请选择单位"
              :clearable="true"
              style="width: 240px"
              filterable
            >
              <el-option
                v-for="(item, index) in titles"
                :key="index"
                :label="item.title"
                :value="item.title"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单价:" :label-width="formLabelWidth">
            <el-input
              v-if="form.tao == 0 || form.tao == 1"
              v-model="form.projectMoney"
              autocomplete="off"
              style="width: 240px"
              disabled
            ></el-input>
            <el-input
              v-if="form.tao == 2"
              v-model="form.projectMoney"
              autocomplete="off"
              style="width: 240px"
            ></el-input>
          </el-form-item>
          <el-form-item label="方量:" :label-width="formLabelWidth">
            <el-input
              v-model="form.number"
              autocomplete="off"
              style="width: 240px"
              @input="countPrice"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="总计金额:" :label-width="formLabelWidth">
            <el-input
              v-model="form.moneyCount"
              autocomplete="off"
              style="width: 240px"
            ></el-input>
          </el-form-item>
          <el-form-item label="清单备注:" :label-width="formLabelWidth">
            <el-input
              v-model="form.remarks"
              autocomplete="off"
              style="width: 240px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            class="mr20"
            type="primary"
            plain
            size="small"
            @click="submitDetail"
            style="margin-left: 35%"
            >提 交</el-button
          >
          <el-button size="small" @click="dialogTypeCancel">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 修改项目名称弹框 -->
    <el-dialog
      title="修改分组名称"
      :visible.sync="changeTitleDialog"
      width="30%"
      append-to-body
      :before-close="handleClose"
    >
      <div>
        <el-input placeholder="请输入修改名称" v-model="afterTitle"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeTitleDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitChangeTitle">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 提醒是否删除项目 -->
    <el-dialog
      title="是否删除"
      :visible.sync="delDialog"
      width="30%"
      append-to-body
    >
      <span>确定要删除该项目吗？（该项目下所有清单也会被一并删除）</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialog = false">取 消</el-button>
        <el-button type="primary" @click="delProject">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["currentPage"],
  data() {
    return {
      projectIndex: 0,
      littleKey3: 6,
      nature: 2,
      attribute: [],
      planKind: "",
      planKinds: [],
      planName: "",
      estateName: "全部",
      house: [],
      houseHold: 9,
      houseHolds: [],
      timeLimit: "",
      btnList: [
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: "",
        },
      ],
      picName: "",
      arr: [],
      tip: "",
      priceRule: "",
      serviceCon: "",
      useMaterial: "",
      serviceTime: "",
      finished: "",
      warrantyMsg: "",
      planIntro: "",
      problems: [],
      problemArr: [],
      labelCheck: [],
      labelCheck1: [],
      citys: [],
      list: [
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
      ],
      taos: [
        {
          id: 0,
          title: "套餐",
        },
        {
          id: 1,
          title: "单个项目",
        },
        {
          id: 2,
          title: "自定义",
        },
      ],
      firstTypes: [],
      projectIds: [],
      option: {
        // border: true,
        size: "medium",
        menu: true,
        page: false,
        align: "center",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, // 列显隐按钮
        refreshBtn: false, // 刷新按钮
        column: [
          {
            label: "项目ID",
            prop: "projectId",
            hide: true,
          },
          {
            label: "类别",
            prop: "tao",
            hide: true,
          },
          {
            label: "是否代购",
            prop: "agency",
            hide: true,
          },
          {
            label: "名称",
            prop: "projectTitle",
          },
          {
            label: "单位",
            prop: "title",
          },
          {
            label: "单价",
            prop: "projectMoney",
          },
          {
            label: "方量",
            prop: "number",
          },
          {
            label: "总价金额",
            prop: "moneyCount",
          },
          {
            label: "备注",
            prop: "remarks",
          },
        ],
      },
      // 图片上传
      mutrue: true,
      dialogImageUrl: "",
      tudiag: false,
      tuzhi: { app_name: "public" },
      imgs: [],
      fileList: [],
      result1: "",
      result2: [],
      problemTitle: [],
      result2s: [],
      formLabelWidth: "200px",
      city: "",
      project: [
        {
          title: "默认项目分组1",
          data: [],
        },
      ],
      titles: [],
      form: {
        tao: "",
        firstType: "",
        projectMoney: "",
        number: "",
        moneyCount: "",
        projectTitle: "",
        remarks: "",
        projectId: "",
        title: "",
        agency: 0,
      },
      dialogFormVisible: false,
      chooseList: [],
      btnArr: [],
      arrType: [],
      planKindArr: [],
      houseType: [],
      changeTitleDialog: false,
      afterTitle: "",
      delDialog: false,
    };
  },
  mounted() {
    this.getChoice();
    this.getcitys();
    this.getData();
  },
  methods: {
    getData() {
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Diagnostic/MasterCreationInfo", {
          id: this.$store.state.plan_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.planName = res.data.name;
            this.planIntro = res.data.info;
            this.timeLimit = res.data.day;
            this.tip = res.data.reminde;
            this.warrantyMsg = res.data.quality;
            this.priceRule = res.data.pricing_rules;
            this.serviceCon = res.data.service_content;
            this.useMaterial = res.data.materials_used;
            this.serviceTime = res.data.service_duration;
            this.finished = res.data.completion;
            this.planKind = res.data.scheme_tag[0].id;
            this.project = res.data.scheme_project;
            this.btnList = res.data.characteristic;
            this.city = res.data.city_id;
            // let arr = [];
            if (res.data.relation.length > 0) {
              this.result1 = res.data.relation[0].goods_category_id;
              this.getRelation();
              for (let i = 0; i < res.data.relation.length; i++) {
                this.result2.push(res.data.relation[i].id);
              }
              // this.result2 = res.data.relation[0].id;
            }
            if (res.data.scheme_project.length != 0) {
              this.project = res.data.scheme_project;
            }

            this.project.map((item) => {
              item.data.map((item1) => {
                item1.number = item1.square;
                item1.moneyCount = item1.to_price;
                item1.title = item1.company;
                item1.tao = item1.category;
              });
              return item;
            });
            if (res.data.img.length != 0) {
              for (let i = 0; i < res.data.img.length; i++) {
                this.fileList.push({
                  url: res.data.img[i].path,
                });
                this.imgs.push({
                  title: res.data.img[i].title,
                  path: res.data.img[i].path,
                });
              }
            }
            this.problems1 = res.data.scheme_tag[0].position;
            this.problems1.map((item) => {
              if (item.type == 1) {
                this.labelCheck.push(item.tag);
              }
              if (item.type == 2) {
                this.labelCheck1.push(item.tag);
              }
            });
            // if (res.data.city_id != "") {
            //   this.getTitles(res.data.city_id);
            //   this.getUnit(res.data.city_id);
            // }
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
          this.getproblems();
          this.getTitles(res.data.city_id);
          this.getUnit(res.data.city_id);
          // this.getTitles(this.city);
          // this.getUnit(this.city);
        });
    },
    // 城市
    getcitys() {
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.citys = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    cityTip() {
      if (this.city == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请先选择城市！",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
    },
    getRelation() {
      this.util
        .get(this.HOST + "/Diagnostic/relation", {
          goods_category: this.result1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.result2s = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    cityChange() {
      this.form.tao = "";
      this.form.firstType = "";
      this.form.projectId = "";
      this.getTitles(this.city);
      this.getUnit(this.city);
    },
    // 关联结果问题
    getTitles(city) {
      if (city) {
        this.util
          .get(this.HOST + "/Common/programme", {
            city_id: city,
          })
          .then((res) => {
            if (res.code == 200) {
              this.problemTitle = res.data;
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    // 获取单位
    getUnit(city) {
      this.util
        .get(this.HOST + "/Diagnostic/Basics", {
          city_id: city,
        })
        .then((res) => {
          if (res.code == 200) {
            this.titles = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getTypes1() {
      this.$forceUpdate();
      this.form.firstType = "";
      this.form.projectId = "";
      this.form.title = "";
      this.form.projectMoney = "";
      this.form.number = "";
      this.form.moneyCount = "";
      this.form.remarks = "";
      this.form.projectTitle = "";
      this.util
        .get(this.HOST + "/Diagnostic/Package", {
          city_id: this.city,
          type: this.form.tao,
        })
        .then((res) => {
          if (res.code == 200) {
            this.firstTypes = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getTypes2() {
      this.arrType = [];
      this.form.projectId = "";
      this.form.title = "";
      this.form.projectMoney = "";
      this.form.number = "";
      this.form.moneyCount = "";
      this.form.remarks = "";
      this.firstTypes.map((item) => {
        if (item.id == this.form.firstType) {
          this.arrType.push(item.data);
        }
        return (this.projectIds = this.arrType[this.arrType.length - 1]);
      });
    },
    getTypeDet() {
      this.util
        .get(this.HOST + "/Diagnostic/PackageInfo", {
          city_id: this.city,
          type: this.form.tao,
          id: this.form.projectId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.form.title = res.data.title;
            if (res.data.artificial) {
              this.form.projectMoney = res.data.artificial;
            } else {
              this.form.projectMoney = res.data.prices;
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    countPrice() {
      this.form.moneyCount = this.form.projectMoney * this.form.number;
    },
    delDetail(row, index) {
      //console.log(row, "row+++++");
      //console.log(index, "index+++++");
      for (var i = this.project[index].data.length - 1; i > -1; i--) {
        if (this.project[index].data[i].$index == row.$index) {
          this.project[index].data.splice(row.$index, 1);
        }
      }
    },
    lookPlanIntro() {},
    addInput() {
      var myobject = { title: "" };
      this.btnList.push(myobject);
    },
    getproblems() {
      this.util
        .post(this.HOST + "/Diagnostic/schemeLabe", {
          param1: this.planKind,
        })
        .then((res) => {
          if (res.code == 200) {
            this.problems = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getChoice() {
      this.util.get(this.HOST + "/Diagnostic/all", {}).then((res) => {
        if (res.code == 200) {
          // this.chooseList = res.data;
          this.attribute = res.data.attribute;
          this.planKinds = res.data.label;
          this.houseHolds = res.data.HouseData;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    kindChange() {
      this.getproblems();
    },
    // 图片上传
    uploadFile(file) {
      this.formDate.append(file, file.file);
    },
    handleRemove(file, fileList) {},
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.tudiag = true;
    },
    upsuccess(response, file, fileList) {
      fileList = JSON.parse(JSON.stringify(fileList));
      //console.log(fileList, "fileList+++++++");
      // this.picName = response.data.file.filename
      this.imgs.push({
        title: response.data.file.filename,
        path: response.data.file.path,
      });
      //console.log(this.imgs);
    },
    sureDel(index) {
      if (this.project.length <= 1) {
        this.$message({
          showClose: true,
          message: "至少保留一个项目！",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.delDialog = true;
      //console.log(this.project);
      this.projectIndex = index;
    },
    addProject() {
      let obj = {
        // key: this.project.length,
        title: "默认项目分组" + Number(this.project.length + 1),
        data: [],
      };
      this.project.push(obj);
      this.$message({
        showClose: true,
        message: "增加分组成功！",
        type: "success",
        duration: 1200,
        offset: 90,
      });
    },
    delProject() {
      if (this.project.length <= 1) {
        this.$message({
          showClose: true,
          message: "至少保留一个项目！",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.project.splice(this.projectIndex, 1);
      this.delDialog = false;
    },
    addDetail(index) {
      // 打开添加清单模态框
      this.projectIndex = index;
      if (this.city == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请先选择城市！",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      this.dialogFormVisible = true;
      this.$nextTick(
        (this.form.tao = ""),
        (this.form.firstType = ""),
        (this.form.title = ""),
        (this.form.projectMoney = ""),
        (this.form.number = ""),
        (this.form.moneyCount = ""),
        (this.form.projectId = ""),
        (this.form.remarks = "")
      );

      // this.littleKey3++
    },

    changeTitle(index) {
      this.projectIndex = index;
      this.afterTitle = this.project[index].title;
      this.changeTitleDialog = true;
    },
    submitChangeTitle() {
      this.project[this.projectIndex].title = this.afterTitle;
      this.changeTitleDialog = false;
    },
    submitDetail() {
      // 添加清单项目
      this.littleKey3++;
      //console.log(this.form, "this.form+++++");
      if (this.form.projectId != "") {
        this.projectIds.map((item) => {
          if (item.id == this.form.projectId) {
            this.form.projectTitle = item.name;
          }
        });
      }
      let form = JSON.parse(JSON.stringify(this.form));
      this.project[this.projectIndex].data.push(form);
      this.dialogFormVisible = false;
      this.firstTypes = [];
      this.projectIds = [];
    },
    closeDialog() {
      this.littleKey3++;
      this.dialogFormVisible = false;
    },
    dialogTypeCancel() {
      this.littleKey3++;
      this.firstTypes = [];
      this.projectIds = [];
      this.dialogFormVisible = false;
    },
    preText(pretext) {
      return pretext
        .replace(/\r\n/g, "<br/>")
        .replace(/\n/g, "<br/>")
        .replace(/\s/g, "&nbsp;");
    },
    referPlan() {
      if (this.planKind == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择方案类别！",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.planName == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入方案名称！",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.timeLimit == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入预计工期！",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.$loading(this.util.opload);
      this.project.map((item) => {
        item.data.map((item1) => {
          delete item1.$index;
          delete item1.moneyCount;
          // const {
          //   tao,
          //   firstType,
          //   projectMoney,
          //   number,
          //   projectTitle,
          //   remarks,
          //   projectId,
          //   title,
          //   agency
          // } = item1;
          // return {
          //   tao,
          //   firstType,
          //   projectMoney,
          //   number,
          //   projectTitle,
          //   remarks,
          //   projectId,
          //   title,
          //   agency
          // };
        });
        return item;
      });
      this.project.map((item) => {
        if (item.data.length == 0) {
          this.$message({
            showClose: true,
            type: "error",
            message: "项目清单项目不能为空！",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
      });
      // 提交整个页面的数据
      //console.log(this.project, "project+++++");
      // return;
      let houseTitle = "";
      if (this.houseHold != "") {
        this.houseHolds.map((item) => {
          if (this.houseHold == item.id) {
            houseTitle = item.title;
          }
        });
        this.houseType.push({ id: this.houseHold, title: houseTitle });
      }
      if (this.estateName != "") {
        this.house.push({ id: 0, area: 0, name: this.estateName });
      } else {
        this.house = [];
      }
      let planKindName = "";
      this.planKinds.map((item) => {
        if (this.planKind == item.id) {
          planKindName = item.title;
        }
      });
      this.planKindArr.push({ id: this.planKind, title: planKindName });
      for (let i = 0; i < this.btnList.length; i++) {
        this.btnList = this.btnList.filter((item, i) => {
          return item.title != "";
        });
      }
      if (this.btnList.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入至少一个特色标签！",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      let relationArr = [];
      for (let i = 0; i < this.result2.length; i++) {
        relationArr.push({ diagnostic_record_id: this.result2[i] });
      }
      if (this.labelCheck.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择方案标签下的位置",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      if (this.labelCheck1.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择方案标签下的问题类型",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      let arr = [];
      this.labelCheck.map((item) => {
        this.problems.map((data) => {
          if (data.position) {
            data.position.map((data1) => {
              if (item === data1.id) {
                if (
                  arr.findIndex((id1) => {
                    return id1.id === data.id;
                  }) > -1
                ) {
                  arr[
                    arr.findIndex((id1) => {
                      return id1.id === data.id;
                    })
                  ].position.push({
                    ...data1,
                  });
                } else {
                  arr.push({
                    id: data.id,
                    title: data.title,
                    position: [{ ...data1 }],
                    problem: [],
                  });
                }
              }
            });
          }
        });
      });
      this.labelCheck1.map((item) => {
        this.problems.map((data) => {
          if (data.problem) {
            data.problem.map((data2) => {
              if (item === data2.id) {
                if (
                  arr.findIndex((id2) => {
                    return id2.id === data.id;
                  }) > -1
                ) {
                  arr[
                    arr.findIndex((id2) => {
                      return id2.id === data.id;
                    })
                  ].problem.push({
                    ...data2,
                  });
                } else {
                  arr.push({
                    id: data.id,
                    title: data.title,
                    position: [],
                    problem: [{ ...data2 }],
                  });
                }
              }
            });
          }
        });
      });
      let mark = 0;
      arr.map((item) => {
        if (item.position.length > 0 && item.problem.length == 0) {
          mark = 1;
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择方案标签下的问题类型标签!",
            duration: 2000,
            offset: 90,
          });
          return false;
        }
        if (item.problem.length > 0 && item.position.length == 0) {
          mark = 1;
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择方案标签下的位置类型标签!",
            duration: 2000,
            offset: 90,
          });
          return false;
        }
      });
      if (mark != 1 && mark != 2) {
        this.problemArr = arr;
      } else {
        return false;
      }
      this.util
        .post(this.HOST + "/Diagnostic/SchemeMasterAdd", {
          schemeId: this.$store.state.plan_id,
          type: this.nature,
          category: JSON.stringify(this.planKindArr),
          name: this.planName,
          communityName: JSON.stringify(this.house),
          houseType: JSON.stringify(this.houseType),
          day: this.timeLimit,
          characteristic: JSON.stringify(this.btnList),
          reminde: this.tip,
          pricing_rules: this.priceRule,
          service_content: this.serviceCon,
          materials_used: this.useMaterial,
          service_duration: this.serviceTime,
          completion: this.finished,
          quality: this.warrantyMsg,
          info: this.planIntro,
          tag: JSON.stringify(this.problemArr),
          project: JSON.stringify(this.project),
          // img: JSON.stringify(this.imgs).replace(/\[|]/g, "").replace(/\"/g, ""),
          img: JSON.stringify(this.imgs),
          schemeRelation: JSON.stringify(relationArr),
          cityId: this.city,
        })
        .then((res) => {
          if ((res.code = 200)) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("setDialog", false);
              this.$store.commit("settask", 1);
              this.$emit("getDataAgain", this.currentPage);
              this.$emit("update:currentPage", this.currentPage);
            }, 1200);
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
            this.$loading().close();
            // this.nature = "";
            // this.planKindArr = [];
            // this.planName = "";
            // this.house = [];
            // this.houseType = [];
            // this.timeLimit = "";
            // this.btnList = [];
            // this.tip = "";
            // this.priceRule = "";
            // this.serviceCon = "";
            // this.useMaterial = "";
            // this.serviceTime = "";
            // this.finished = "";
            // this.warrantyMsg = "";
            // this.planIntro = "";
            // this.problemArr = [];
            // this.project = [];
            // this.imgs = [];
            // relationArr = [];
            // this.city = "";
          }
        });
    },
    handleClose() {
      this.changeTitleDialog = false;
    },
  },
};
</script>
<style scoped>
.liTitle {
  width: 70px;
  text-align: center;
}
.liTitle2 {
  width: 100px;
}
.littleBox input {
  height: 25px !important;
  line-height: 25px !important;
}
div /deep/ .el-input__inner {
  height: 30px;
  line-height: 30px;
}
div /deep/ .avue-crud__menu {
  display: none;
}
.titleChange {
  text-decoration: underline;
}
.titleChange:hover {
  color: orange;
}
</style>
